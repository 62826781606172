import { precacheAndRoute } from "workbox-precaching";
import {
  pageCache,
  imageCache,
  staticResourceCache,
  offlineFallback,
} from 'workbox-recipes';
// import { registerRoute } from "workbox-routing";
// import { StaleWhileRevalidate } from "workbox-strategies";

precacheAndRoute(self.__WB_MANIFEST);

pageCache({
  warmCache: [
    "/",
    "/?source=pwa",
    "/programmation/",
    "/programmation/?view=day",
    "/programmation/?view=day&timetable=1",
    "/programmation/?source=pwa",
    "/infos-pratiques/",
  ]
});

staticResourceCache({
  warmCache: [
    "/wp-content/uploads/2023/07/PLANNING-CAMPING-4.pdf",
  ]
});

imageCache({
  // match images & svg
  matchCallback: ({ request, url }) => {
    return request.destination === 'image' || url.pathname.endsWith('.svg');
  },
  warmCache: [
    '/wp-content/themes/no-logo/img/scenes/grande-scene--timetable.svg',
    '/wp-content/themes/no-logo/img/scenes/dub-factory--timetable.svg',
    '/wp-content/themes/no-logo/img/scenes/ch-apero--timetable.svg',
    '/wp-content/themes/no-logo/img/icon-arrow--timetable.svg',
    '/wp-content/themes/no-logo/img/logo-text--white.svg',
    '/wp-content/themes/no-logo/img/logo_nologo.svg',
    '/wp-content/themes/no-logo/img/logo--festival.svg',
  ]
});

offlineFallback({
  pageFallback: '/offline/',
});

addEventListener("message", (event) => {
  if (event.data && event.data.type === "CACHE_WP_URLS") {
    const urls = event.data.payload;
    urls.forEach((url) => {
      const urlCacheKey = new URL(url, self.location).href;
      caches.open("pages").then((cache) => cache.add(urlCacheKey));
    });
  }
});